import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const CreditCardLogos = (props) => (
  <StaticQuery
    query={graphql`
      {
        placeholderImage: file(relativePath: { eq: "credit-card-logos.png" }) {
          childImageSharp {
            gatsbyImageData(width: 185, height: 27, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <GatsbyImage
        alt="Credit Cards"
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        className={props.className}
        style={{ width: "185px", height: "27px" }}
      />
    )}
  />
);
export default CreditCardLogos;
